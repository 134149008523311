.hl-code {
  font-size: 16px;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 0.5em;
  h3 {
    margin-top: 0;
  }
  p {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    code {
      display: inline-block;
      padding: 2px 4px;
      background: #e7f0fa;
      font-family: monospace;
    }
  }
  & > pre {
    margin-bottom: 0;
    & pre > code {
      font-family: monospace;
    }
  }
}
