.login-form {
  .MuiInputBase-root {
    color: #3b3e66;
  }
  .MuiFormLabel-root {
    color: #999;
    &.Mui-disabled {
      color: #bbb;
    }
  }
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.3);
    }
    &.Mui-disabled {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.15);
        color: black;
      }
    }
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.3);
      }
    }
  }
  .MuiButtonBase-root {
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
  .MuiAlert-standardError {
    color: rgb(84, 18, 18);
    background-color: rgb(250, 234, 234);
  }
  .MuiOutlinedInput-input:-webkit-autofill {
    box-shadow: none;
    -webkit-text-fill-color: inherit;
  }
}

.login-pass {
  .MuiInputBase-input {
    opacity: 0;
    pointer-events: auto;
  }
}
